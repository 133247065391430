/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('.scrollup').fadeIn();
                } else {
                    $('.scrollup').fadeOut();
                }
            });
            $('.scrollup').click(function () {
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
                return false;
            });
        });

        if (!Cookies.get('alert') && $('#modal').length) {
            $('#modal').show();
        }
          
        $('.modal .btn-close').click(function() {
            $('#modal').hide();
            Cookies.set('alert', true, {
                expires: 1,
            });
        });

        $(".slide-link" ).bind( "click", function() {
            $(".top-dropdown").toggleClass('open');
            return false;
        });
        $(document).ready(function () {
            $('.banner').unslider({
                speed: 1,               //  The speed to animate each slide (in milliseconds)
                delay: 3000,              //  The delay between slide animations (in milliseconds)
                complete: function () {
                },  //  A function that gets called after every slide animation
                keys: false,               //  Enable keyboard (left, right) arrow shortcuts
                dots: true,               //  Display dot navigation
                fluid: true              //  Support responsive design. May break non-responsive designs
            });

            $('.mobileMenu').click(function () {
                $('.menuContainer').toggleClass('open');
                return false;
            });

            function stickFooter() {
                var footer = $(".footer-container");
                var pos = footer.position();
                var height = $(window).height();
                height = height - pos.top;
                height = height - footer.outerHeight();

                if (height > 0) {
                    footer.css({'margin-top': height + 'px'});
                    console.log("Changing....");
                }
            }

            stickFooter();
            $(window).resize(function () {
                stickFooter();
            });

        });
        var map;
        var thisLocation = new google.maps.LatLng(44.818487, -68.77464420000001);

        function initialize() {

            var roadAtlasStyles = [

                {
                    featureType: "all",
                    stylers: [
                        {
                            saturation: 0
                        }
                    ]
                },
                {
                    featureType: "poi.business",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {}
            ];

            var mapOptions = {
                zoom: 15,
                scrollwheel: false,
                navigationControl: false,
                mapTypeControl: false,
                scaleControl: false,
                draggable: true,
                center: new google.maps.LatLng(44.8199, -68.77464420000001),
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'usroadatlas']
                }
            };

            map = new google.maps.Map(document.getElementById('map-canvas'),
                mapOptions);

            var styledMapOptions = {

            };

            var contentString = '<div id="content">' +
                '<div id="siteNotice">' +
                '</div>' +
                '<h1 class="mapLogo">Company Name</h1>' +
                '<div id="bodyContent">' +
                '<p>' +
                'Some text describing your location relative to well know landmarks in your area.' +
                '</div>' +
                '</div>';

            var infowindow = new google.maps.InfoWindow({
                content: contentString,
                maxWidth: 300
            });

            var marker = new google.maps.Marker({
                position: thisLocation,
                map: map,
                title: "COMPANY NAME"
            });

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
            infowindow.open(map, marker);

            var usRoadMapType = new google.maps.StyledMapType(
                roadAtlasStyles, styledMapOptions);

            map.mapTypes.set('usroadatlas', usRoadMapType);
            map.setMapTypeId('usroadatlas');
        }

        google.maps.event.addDomListener(window, 'load', initialize);

        // Video Playlist
        $('#vidplay .playlist .playlist-item').on('click', function(event) {
          event.preventDefault();
          $('#vidplay .playlist .playlist-item.playing').removeClass('playing');
          var src = $(this)
            .addClass('playing')
            .data('src');
          $('#vidplay .player').html('<iframe src="' + src + '" allowfullscreen></iframe>').load();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



(function (e, t) {
    if (!e) {
      return t;
    }
    var N = function () {
        this.el = t;
        this.items = t;
        this.sizes = [];
        this.max = [0, 0];
        this.current = 0;
        this.interval = t;
        this.opts = {speed: 500, delay: 3e3, complete: t, keys: !t, dots: t, fluid: t};
        var n = this;
        this.init = function (t, n) {
            this.el = t;
            this.ul = t.children("ul");
            this.max = [t.outerWidth(), t.outerHeight()];
            this.items = this.ul.children("li").each(this.calculate);
            this.opts = e.extend(this.opts, n);
            this.setup();
            return this;
        };
        this.calculate = function (t) {
            var r = e(this);
            var i = r.outerWidth();
            var s = r.outerHeight();
            n.sizes[t] = [i, s];
            if (i > n.max[0]) {
              n.max[0] = i;
            }
            if (s > n.max[1]) {
              n.max[1] = s;
            }
        };
        this.setup = function () {
            this.el.css({overflow: "hidden", width: n.max[0], height: this.items.first().outerHeight()});
            this.ul.css({width: this.items.length * 100 + "%", position: "relative"});
            this.items.css("width", 100 / this.items.length + "%");
            if (this.opts.delay !== t) {
                this.start();
                this.el.hover(this.stop, this.start);
            }
            const aaa = this.opts.keys && e(document).keydown(this.keys);
            const bbb = this.opts.dots && this.dots();
            if (this.opts.fluid) {
                var r = function () {
                    n.el.css("width", Math.min(Math.round(n.el.outerWidth() / n.el.parent().outerWidth() * 100), 100) + "%");
                };
                r();
                e(window).resize(r);
            }
            if (this.opts.arrows) {
              var test = '<p class="arrows"><span class="prev">â†</span><span class="next">â†’</span></p>';
              this.el.parent().append(test).find(".arrows span").click(function(){
                const ccc = e.isFunction(n[this.className]) && n[this.className]();
              });
            }
            if (e.event.swipe) {
                this.el.on("swipeleft", n.prev).on("swiperight", n.next);
            }
        };
        this.move = function (t, r) {
            if (!this.items.eq(t).length) {
              t = 0;
            }
            if (t < 0) {
              t = this.items.length - 1;
            }
            var i = this.items.eq(t);
            var s = {height: i.outerHeight()};
            var o = r ? 5 : this.opts.speed;
            if (!this.ul.is(":animated")) {
                n.el.find(".dot:eq(" + t + ")").addClass("active").siblings().removeClass("active");
                const ddd = this.el.animate(s, o) && this.ul.animate(e.extend({left: "-" + t + "00%"}, s), o, function (i) {
                    n.current = t;
                    const eee = e.isFunction(n.opts.complete) && !r && n.opts.complete(n.el);
                });
            }
        };
        this.start = function () {
            n.interval = setInterval(function () {
                n.move(n.current + 1);
            }, n.opts.delay);
        };
        this.stop = function () {
            n.interval = clearInterval(n.interval);
            return n;
        };
        this.keys = function (t) {
            var r = t.which;
            var i = {37: n.prev, 39: n.next, 27: n.stop};
            if (e.isFunction(i[r])) {
                i[r]();
            }
        };
        this.next = function () {
            return n.stop().move(n.current + 1);
        };
        this.prev = function () {
            return n.stop().move(n.current - 1);
        };
        this.dots = function () {
            var t = '<ol class="dots">';
            e.each(this.items, function (e) {
                t += '<li class="dot' + (e < 1 ? " active" : "") + '">' + (e + 1) + "</li>";
            });
            t += "</ol>";
            this.el.addClass("has-dots").append(t).find(".dot").click(function () {
                n.move(e(this).index());
            });
        };
    };
    e.fn.unslider = function (t) {
        var r = this.length;
        return this.each(function (i) {
            var s = e(this);
            var u = new N();
            u.init(s, t);
            s.data("unslider" + (r > 1 ? "-" + (i + 1) : ""), u);
        });
    };
})(window.jQuery, false);
